// Pager
//
// Paged navigation is a list of page numbers when more than 1 page of content
// is available.
//
// Markup: pager.twig
//
// Style guide: components.navigation.pager

.pager,
%pager {
  clear: both;
  padding: 0;
  margin: 0;
  text-align: center;

  @media print {
    display: none;
  }

  // A page item in the pager list.
  &__item {
    display: inline;
    padding: 0 .2em;
    list-style-type: none;
    background-image: none;

    a{
      text-decoration: none;
    }
  }

  // The current page's list item.
  &__current-item {
    @extend %pager__item;
  }
}

//
// Drupal selectors.
//

// .pager-item     - A list item containing a page number in the list of pages.
// .pager-first    - The first page's list item.
// .pager-previous - The previous page's list item.
// .pager-next     - The next page's list item.
// .pager-last     - The last page's list item.
// .pager-ellipsis - A concatenation of several list items using an ellipsis.

.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis,
.pager-divider {
  @extend %pager__item;
}

.pager-divider {
  padding: 0;
}

.pager-current {
  @extend %pager__current-item;
}

.pager-container{
  clear: both;
  overflow: hidden;

  .pager-label{
    display: inline-block;
    margin-right: 4px;
  }

  .item-list{
    display: inline-block;

    &:last-child{
      float: right;
    }
  }
}