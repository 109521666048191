/*! typey | GPLv2 License | https://github.com/jptaranto/typey */
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?x9jvmv");
  src: url("../fonts/icomoon.eot?x9jvmv#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?x9jvmv") format("truetype"), url("../fonts/icomoon.woff?x9jvmv") format("woff"), url("../fonts/icomoon.svg?x9jvmv#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 44px;
  font-size: 32px;
  width: 32px;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  -o-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a[class^='icon-'],
a[class*=' icon-'] {
  text-decoration: none;
}

a[class^='icon-']:before,
a[class*=' icon-']:before {
  margin-right: 50px;
  width: 1.2em;
  display: inline-block;
}

.icon-google {
  width: 44px;
}

.icon-checkmark:before {
  content: '\ea10';
}

.icon-circle-up:before {
  content: '\ea41';
}

.icon-circle-right:before {
  content: '\ea42';
}

.icon-circle-down:before {
  content: '\ea43';
}

.icon-circle-left:before {
  content: '\ea44';
}

.icon-mail4:before {
  content: '\ea86';
}

.icon-google-plus:before {
  content: '\ea8b';
}

.icon-facebook:before {
  content: '\ea90';
}

.icon-instagram:before {
  content: '\ea92';
}

.icon-twitter:before {
  content: '\ea96';
}

.icon-rss2:before {
  content: '\ea9c';
}

.icon-youtube:before {
  content: '\ea9d';
}

.icon-vimeo2:before {
  content: '\eaa1';
}

.icon-flickr3:before {
  content: '\eaa5';
}

.icon-tumblr:before {
  content: '\eab9';
}

.icon-skype:before {
  content: '\eac5';
}

.icon-linkedin:before {
  content: '\eac9';
}

.icon-pinterest:before {
  content: '\ead1';
}

.icon-xing:before {
  content: '\ead3';
}

.icon-search:before {
  content: "\e986";
}

* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  line-height: 1.5em;
  font-family: "Roboto", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
  background: url("../images/bg.png") 0 0 repeat;
}

@media print {
  html {
    font-size: 12pt;
  }
}

body {
  margin: 0;
  padding: 0;
  color: #333;
  background-size: auto 60vh !important;
}

@media (min-width: 555px) {
  body {
    background-size: 100% auto !important;
  }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

template {
  display: none;
}

:link {
  color: #ff6f2a;
}

:visited {
  color: #c33f00;
}

a:active {
  color: #c00;
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 16px;
    text-decoration: none;
  }
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
}

h1,
.header__site-name,
h2,
h3 {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
}

h1 a, .header__site-name a,
h2 a,
h3 a {
  text-decoration: none;
}

h1,
.header__site-name {
  font-size: 28px;
  line-height: 1.4;
  margin-top: 1em;
  margin-bottom: 1em;
}

h2 {
  font-size: 20px;
  line-height: 1.4;
  margin-top: 1em;
  margin-bottom: 1em;
}

h3 {
  font-size: 18px;
  line-height: 1.4;
  margin-top: 1em;
  margin-bottom: 1em;
}

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h6 {
  font-size: 0.625rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 888px) {
  h1,
  .header__site-name {
    font-size: 2.875rem;
    line-height: 3rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  h2 {
    font-size: 2rem;
    line-height: 2.25rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  h3 {
    font-size: 1.25rem;
    line-height: 2.1rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.page_title {
  background-color: rgba(0, 0, 0, 0.5);
  max-width: 680px;
  padding: 3.2%;
  color: #fff;
  margin: 30px 20px 40px;
}

@media (min-width: 888px) {
  .page_title {
    margin: 35vh 0 3%;
  }
  .page-search .page_title {
    margin: 40px 0 40px;
  }
}

.node-type-article h1 {
  text-transform: none;
}

abbr[title] {
  border-bottom: 1px dotted;
}

@media print {
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #fd0;
  color: #333;
}

small {
  font-size: 0.875rem;
}

sub,
sup {
  font-size: 0.625rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

.divider,
hr {
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #cccccc;
}

.divider > :first-child, hr > :first-child {
  margin-top: 1.5rem;
}

dl,
menu,
ol,
ul {
  margin: 1.5rem 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dd {
  margin: 0 0 0 32px;
}

[dir='rtl'] dd {
  margin: 0 32px 0 0;
}

menu,
ol,
ul {
  padding: 0 0 0 32px;
}

[dir='rtl'] menu, [dir='rtl']
ol, [dir='rtl']
ul {
  padding: 0 32px 0 0;
}

blockquote {
  margin: 1.5rem 2rem;
}

figure {
  margin: 0 1.5rem;
}

hr {
  box-sizing: content-box;
  height: 0;
}

p,
pre {
  margin: 0 0 1.5rem 0;
}

code,
kbd,
samp,
var, pre {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

pre {
  overflow: auto;
}

img {
  border: 0;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

.button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -moz-appearance: button;
  -webkit-appearance: button;
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #333;
}

.button:hover, .button:focus, .button:active,
button:hover,
[type='button']:hover,
[type='reset']:hover,
[type='submit']:hover,
button:focus,
[type='button']:focus,
[type='reset']:focus,
[type='submit']:focus,
button:active,
[type='button']:active,
[type='reset']:active,
[type='submit']:active {
  text-decoration: none;
  color: #333;
}

.button[disabled],
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'] {
  cursor: default;
  color: #999999;
}

.button[disabled]:hover, .button[disabled]:focus, .button[disabled]:active,
button[disabled]:hover,
[disabled][type='button']:hover,
[disabled][type='reset']:hover,
[disabled][type='submit']:hover,
button[disabled]:focus,
[disabled][type='button']:focus,
[disabled][type='reset']:focus,
[disabled][type='submit']:focus,
button[disabled]:active,
[disabled][type='button']:active,
[disabled][type='reset']:active,
[disabled][type='submit']:active {
  color: #999999;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

html input[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

input[type='search'] {
  -webkit-appearance: textfield;
}

input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type='text'], input[type='search'], input[type='tel'], input[type='url'], input[type='email'], input[type='password'], input[type='date'], input[type='time'], input[type='number'], textarea {
  line-height: inherit;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 0.525rem 0.9375rem 0.975rem;
  border: 1px solid #cccccc;
  margin: 0 2px;
}

legend {
  border: 0;
  margin-left: -5px;
  padding: 0 5px;
}

label {
  display: block;
  font-weight: bold;
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
}

table {
  margin: 1.5rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
}

.layout-3col {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 0;
  padding-right: 0;
}

.layout-3col:before {
  content: "";
  display: table;
}

.layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  clear: both;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 777px) {
  .layout-3col {
    margin-left: -12px;
    margin-right: -13px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  [dir="rtl"] .layout-3col {
    margin-left: -13px;
    margin-right: -12px;
  }
  .layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__first-left-sidebar, .layout-3col__col-1, .layout-3col__col-3, .layout-3col__col-x:nth-child(2n + 1) {
    float: left;
    width: 50%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__second-left-sidebar, .layout-3col__col-2, .layout-3col__col-4, .layout-3col__col-x:nth-child(2n) {
    clear: none;
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    float: right;
    margin-right: 50%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    padding-left: 13px;
    padding-right: 12px;
  }
}

@media (min-width: 999px) {
  .layout-3col {
    /*
    &__left-content {
      @include zen-grid-item(2, 1);
    }

    &__right-content {
      @include zen-grid-item(2, 2);
    }

    &__left-sidebar,
    &__first-left-sidebar,
    &__second-left-sidebar {
      @include zen-new-row(right);
      @include zen-grid-item(1, 3, right);
    }

    &__right-sidebar {
      @include zen-new-row(right);
      @include zen-grid-item(1, 1, right);
    }
    */
  }
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col .content-wrap {
    display: flex;
    flex: 1;
    flex-direction: row;
  }
  .layout-3col__left-content {
    margin: 0;
    flex: 1;
  }
  .layout-3col__right-sidebar {
    flex: 0 0 314px;
    margin: 0;
  }
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    float: right;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  .layout-3col__col-4 {
    display: none;
  }
}

.layout-3col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

.layout-3col__grid-item-container:before {
  content: "";
  display: table;
}

.layout-3col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

.layout-center {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  max-width: 1238px;
}

@media (min-width: 777px) {
  .layout-center {
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-center {
    padding-left: 13px;
    padding-right: 12px;
  }
}

.layout-center--shared-grid,
.layout-center.layout-3col {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 999px) {
  .main-wrap .layout-3col__full {
    padding: 0;
  }
}

.layout-swap {
  position: relative;
  padding-top: 60px;
}

@media (min-width: 555px) {
  .layout-swap__top {
    position: absolute;
    top: 0;
    height: 60px;
    width: 100%;
  }
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/components/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir='rtl'] .autocomplete,
[dir='rtl'] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #333;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/components/autocomplete/throbber-active.gif);
}

.box {
  margin-bottom: 1.5rem;
  border: 5px solid #cccccc;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #333;
}

.box--highlight {
  border-color: #ff6f2a;
}

.visually-hidden,
.visually-hidden--focusable,
.element-focusable,
.element-invisible,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

@media print {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.clearfix:before,
.header:before,
.tabs:before {
  content: '';
  display: table;
}

.clearfix:after,
.header:after,
.tabs:after {
  content: '';
  display: table;
  clear: both;
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/components/collapsible-fieldset/expanded.svg);
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir='rtl'] .collapsible-fieldset__legend,
[dir='rtl'] .collapsible .fieldset-legend,
.collapsible [dir='rtl'] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #999999;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper, .is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, .collapsible-fieldset--is-collapsed__legend, .is-collapsed.collapsible .collapsible-fieldset__legend,
.collapsible.collapsed .fieldset-legend,
.collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible .collapsible-fieldset.is-collapsed .fieldset-legend,
.is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/components/collapsible-fieldset/collapsed.svg);
  background-position: 4px 50%;
}

[dir='rtl'] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, [dir='rtl'] .collapsible-fieldset--is-collapsed__legend, [dir='rtl'] .is-collapsed.collapsible .collapsible-fieldset__legend,
[dir='rtl'] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir='rtl'] .fieldset-legend,
[dir='rtl'] .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible [dir='rtl'] .collapsible-fieldset.is-collapsed .fieldset-legend,
[dir='rtl'] .is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/components/collapsible-fieldset/collapsed-rtl.svg);
  background-position: right 4px top 50%;
}

input {
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
}

.form-text {
  border: 1px solid #aaa;
}

.form-item {
  margin: 1.5rem 0;
}

.form-item__required,
.form-required {
  color: #c00;
}

.form-item__description,
.form-item .description {
  font-size: 0.875rem;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio .form-item__label, .form-item--radio__label, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-weight: normal;
}

.form-item--radio .form-item__description, .form-item--radio__description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description,
.form-item--radio .form-item .description,
.form-item .form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #cccccc;
  padding-right: 1em;
  text-align: left;
}

[dir='rtl'] .form-table__header,
[dir='rtl'] .form-table th,
.form-table [dir='rtl'] th,
[dir='rtl'] form table th,
form table [dir='rtl'] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #cccccc;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #cccccc;
  background-color: #eeeeee;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #dddddd;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #cccccc;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.header__logo {
  float: left;
  margin: 0 10px 0 0;
  padding: 0;
}

[dir='rtl'] .header__logo {
  float: right;
  margin: 0 0 0 10px;
}

.header__logo-image {
  vertical-align: bottom;
}

.header__name-and-slogan {
  float: left;
}

.header__site-name {
  margin: 0;
}

.header__site-link:link, .header__site-link:visited {
  color: #333;
  text-decoration: none;
}

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline;
}

.header__site-slogan {
  margin: 0;
}

.header__secondary-menu {
  float: right;
}

[dir='rtl'] .header__secondary-menu {
  float: left;
}

.header__region {
  clear: both;
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #c00;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir='rtl'] .inline-links__item,
[dir='rtl'] .inline.links li,
.inline.links [dir='rtl'] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir='rtl'] .inline-sibling,
[dir='rtl'] .field-label-inline .field-label,
.field-label-inline [dir='rtl'] .field-label,
[dir='rtl'] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.messages,
.messages--status {
  margin: 1.5rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #0072b9;
}

[dir='rtl'] .messages,
[dir='rtl'] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir='rtl'] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #0072b9;
}

.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #333;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: #f6fcff;
  color: #0072b9;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #333;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff0f0;
  color: #c00;
}

.messages--warning,
.messages.warning {
  border-color: #fd0;
}

.messages--warning path, .messages.warning path {
  fill: #fd0;
}

.messages--error,
.messages.error {
  border-color: #c00;
}

.messages--error path, .messages.error path {
  fill: #c00;
}

.more-link,
.more-help-link {
  text-align: right;
}

[dir='rtl'] .more-link,
[dir='rtl'] .more-help-link {
  text-align: left;
}

.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/components/more-link/more-link__help-icon.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir='rtl'] .more-link__help-icon,
[dir='rtl'] .more-help-link a,
.more-help-link [dir='rtl'] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.nav-menu__item,
.menu__item {
  list-style-image: url(../sass/components/nav-menu/leaf.svg);
  list-style-type: square;
}

.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-image: url(../sass/components/nav-menu/expanded.svg);
  list-style-type: circle;
}

.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-image: url(../sass/components/nav-menu/collapsed.svg);
  list-style-type: disc;
}

[dir='rtl'] .nav-menu__item.is-collapsed, [dir='rtl'] .nav-menu__item--is-collapsed,
[dir='rtl'] .is-collapsed.menu__item,
[dir='rtl'] .menu__item.is-collapsed {
  list-style-image: url(../sass/components/nav-menu/collapsed-rtl.svg);
}

.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #333;
}

.navbar,
.main-navigation .links,
.main-navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

[dir='rtl'] .navbar,
[dir='rtl'] .main-navigation .links,
.main-navigation [dir='rtl'] .links,
[dir='rtl']
.main-navigation .menu,
.main-navigation [dir='rtl'] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li {
  float: left;
  padding: 0 10px 0 0;
  list-style-type: none;
  list-style-image: none;
}

[dir='rtl'] .navbar__item, [dir='rtl']
.navbar li, [dir='rtl'] .main-navigation .links li, .main-navigation [dir='rtl'] .links li, [dir='rtl']
.main-navigation .menu li,
.main-navigation [dir='rtl'] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

.pager {
  clear: both;
  padding: 0;
  margin: 0;
  text-align: center;
}

@media print {
  .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis,
.pager-divider {
  display: inline;
  padding: 0 .2em;
  list-style-type: none;
  background-image: none;
}

.pager__item a, .pager__current-item a, .pager-current a, .pager-item a,
.pager-first a,
.pager-previous a,
.pager-next a,
.pager-last a,
.pager-ellipsis a,
.pager-divider a {
  text-decoration: none;
}

.pager-divider {
  padding: 0;
}

.pager-container {
  clear: both;
  overflow: hidden;
}

.pager-container .pager-label {
  display: inline-block;
  margin-right: 4px;
}

.pager-container .item-list {
  display: inline-block;
}

.pager-container .item-list:last-child {
  float: right;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir='rtl'] .progress-throbber,
[dir='rtl'] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/components/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir='rtl'] .progress-throbber__widget,
[dir='rtl'] .ajax-progress .throbber,
.ajax-progress [dir='rtl'] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/components/resizable-textarea/grippie.png) no-repeat center 2px #eeeeee;
  border: 1px solid #cccccc;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper,
.embedded-video .player,
.media_embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe,
.embedded-video .player iframe,
.media_embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #666666;
  color: #fff;
}

.skip-link__wrapper {
  margin: 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

[dir='rtl'] .table-drag__handle,
[dir='rtl'] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass/components/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover, .is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle,
.table-drag__handle.is-hover .tabledrag-handle .handle,
.tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir='rtl'] .table-drag__toggle-weight-wrapper,
[dir='rtl'] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir='rtl'] .table-drag__indentation,
[dir='rtl'] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/components/table-drag/tree-child.png) no-repeat 11px center;
}

[dir='rtl'] .table-drag__tree-child,
[dir='rtl'] .table-drag__tree-child-last,
[dir='rtl'] .tree-child-last,
[dir='rtl'] .table-drag__tree-child-horizontal,
[dir='rtl'] .tree-child-horizontal,
[dir='rtl'] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/components/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}

.tabs {
  margin: 1.125rem 0 0;
  line-height: 1.875rem;
  border-bottom: 1px solid #cccccc \0/ie;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
}

@media print {
  .tabs {
    display: none;
  }
}

.tabs__tab {
  float: left;
  margin: 0 3px;
  border: 1px solid #cccccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie;
  overflow: hidden;
  background: #dddddd;
}

[dir='rtl'] .tabs__tab {
  float: right;
}

.tabs__tab.is-active {
  border-bottom-color: #fff;
}

.tabs__tab-link {
  padding: 0 1.5rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
  color: #333;
  background: #dddddd;
  letter-spacing: 1px;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #eaeaea;
}

.tabs__tab-link:active, .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  background: #fff;
  text-shadow: none;
}

.tabs--off,
.views-displays .secondary {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  background-image: none;
}

.tabs--secondary {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  background-image: none;
}

.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
}

.tabs--secondary .tabs__tab {
  margin: 0.5625rem 0.1875rem;
  border: 0;
  background: transparent;
}

[dir='rtl'] .tabs--secondary .tabs__tab {
  float: right;
}

.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

.tabs--secondary .tabs__tab-link {
  border: 1px solid #cccccc;
  border-radius: 24px;
  color: #666666;
  background: #f1f1f1;
  letter-spacing: normal;
}

.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: #333333;
  background: #dddddd;
  border-color: #999999;
}

.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: white;
  text-shadow: #333333 0 1px 0;
  background: #666666;
  border-color: black;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 110;
}

.in-maintenance .header {
  position: static;
}

.header:before {
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background: #2d2d2d;
}

.header .layout-center {
  position: static;
  height: 100%;
  padding: 0;
}

.header .block > .menu {
  margin: 0;
  padding: 0;
}

.header .block > .menu > .menu__item.mega-dropdown > .menu__link:before,
.header .block > .menu > .menu__item.expanded > .menu__link:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 5px 0;
  border-color: #fff transparent transparent;
  content: '';
  position: absolute;
  right: 5px;
  top: 20px;
}

.header .block > .menu > .menu__item.mega-dropdown > .menu__link:before {
  display: none;
}

.header .block > .menu .menu-attach-block-drop-link {
  display: none;
}

.header *:focus,
.header *:active {
  outline: 0 none;
}

.admin-menu .header {
  top: 29px;
}

@media (min-width: 888px) {
  .header .header__region > .block-search,
  .header .header__region > .block-block {
    z-index: 111;
    position: relative;
  }
}

.header__logo {
  position: relative;
  z-index: 50;
}

.header__logo img {
  width: 124px;
}

.region-header {
  margin: 0;
  clear: both;
  background: #2d2d2d;
  display: none;
  position: relative;
}

.region-header > .block > .menu > .menu__item {
  list-style: none;
  display: block;
  padding: 0 10px 0 10px;
}

.region-header > .block > .menu > .menu__item > .menu__link {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  padding: 14px 8px 0 8px;
  font-size: 14px;
  display: block;
  height: 50px;
  position: relative;
  border-bottom: 1px dotted #cccccc;
}

.region-header > .block > .menu > .menu__item > .menu__link .is-active-trail {
  border-left: 5px #ff6f2a solid;
}

.region-header > .block > .menu > .menu__item.mega-dropdown > .menu__link,
.region-header > .block > .menu > .menu__item.is-expanded > .menu__link {
  padding-right: 25px;
}

.region-header > .block > .menu > .menu__item .menu {
  display: none;
}

.region-header > .block > .menu > .menu__item .menu__item {
  padding: 13px 10px;
  list-style: none;
  display: block;
  border-bottom: 1px dotted #cccccc;
}

.region-header > .block > .menu > .menu__item .menu__item a {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-size: 14px;
}

.region-header > .block > .menu > .menu__item.active .menu {
  display: block;
}

.region-header > .block > .menu > .menu__item.active > .menu__link {
  background: #fff;
  color: #000;
}

.mobile-menu-btn {
  background: none;
  border: 0 none;
  height: 60px;
  padding: 0;
  width: 60px;
  float: left;
  color: #fff;
  position: relative;
  z-index: 60;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.mobile-menu-btn .line {
  background-color: #fff;
  height: 3px;
  display: block;
  position: absolute;
  left: 16px;
  right: 16px;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.mobile-menu-btn span:nth-child(1) {
  top: 22px;
}

.mobile-menu-btn span:nth-child(2) {
  top: 29px;
}

.mobile-menu-btn span:nth-child(3) {
  top: 36px;
}

.mobile-menu-active .header {
  height: 100%;
}

.mobile-menu-active .region-header {
  display: block;
}

.mobile-menu-active .mobile-menu-btn span:nth-child(1) {
  top: 30px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.mobile-menu-active .mobile-menu-btn span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.mobile-menu-active .mobile-menu-btn span:nth-child(3) {
  top: 30px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

@media (min-width: 888px) {
  .region-header {
    clear: none;
    background: transparent;
    display: block;
  }
  .region-header > .block {
    float: left;
    margin: 10px 0 0 0;
  }
  .region-header > .block > .menu > .menu__item {
    display: inline-block;
    padding: 0 0 0 8px;
  }
  .region-header > .block > .menu > .menu__item > .menu__link {
    padding-top: 10px;
    border: 0;
  }
  .region-header > .block > .menu > .menu__item .menu__item {
    padding: 0;
    border: 0;
  }
  a.menu-attach-block-drop-link ~ .menu-attach-block-wrapper,
  .header .block > .menu > .menu__item.mega-dropdown > .menu__link:before {
    display: block;
  }
  .mobile-menu-btn {
    display: none;
  }
  .header .block > .menu > .menu__item.mega-dropdown.active > .menu__link:before {
    border-color: #000 transparent transparent;
  }
  .menu-attach-block-wrapper,
  .header .block .menu__item.is-expanded > .menu {
    background: #fff;
    left: 0;
    position: absolute !important;
    width: 100%;
    box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.2);
    padding: 20px 15px;
    top: -1000px;
    z-index: -1;
    opacity: .5;
    transition: ease-in-out 1s top, ease-in-out 1s opacity;
  }
  .menu-attach-block-wrapper li,
  .header .block .menu__item.is-expanded > .menu li {
    list-style: none;
    background: none;
  }
  .header .block > .menu > .menu__item.active > .menu-attach-block-wrapper,
  .header .block > .menu > .menu__item.active > .menu {
    top: 60px !important;
    opacity: 1 !important;
  }
  .menu-attach-block-wrapper h2 {
    font-size: 1.3em;
    margin: 10px 0;
  }
  .menu-attach-block-wrapper.orientation-horizontal .block {
    overflow: visible;
    background: none;
    padding: 0;
    margin: 0 auto;
    max-width: 1213px;
    position: static;
  }
  .header .block .menu__item.is-expanded > .menu {
    background: #999999;
    left: auto;
    width: 220px;
  }
  .header .block .menu__item.is-expanded > .menu a {
    color: #fff;
    text-decoration: none;
  }
}

/*************************************/
.region-footer {
  background: #2d2d2d;
  color: #fff;
  padding: 30px 0 10px;
  font-size: 13px;
}

.region-footer:before {
  content: '';
  display: table;
}

.region-footer:after {
  content: '';
  display: table;
  clear: both;
}

.region-footer p {
  margin-bottom: 10px;
}

@media (min-width: 999px) {
  .region-footer p.brand {
    margin-bottom: 40px;
  }
}

.region-footer a,
.region-footer a.active {
  color: #fff;
  text-decoration: none;
}

.region-footer :link,
.region-footer :visited {
  color: #fff;
}

.region-footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.region-footer ul li {
  list-style: none;
  background: none;
  line-height: 1.8;
}

.region-footer .block {
  width: 100%;
  display: block;
  vertical-align: top;
}

.region-footer .block .menu__item a {
  text-transform: uppercase;
  text-decoration: none;
  line-height: 2em;
}

@media (min-width: 999px) {
  .region-footer .block {
    width: 33.3%;
    float: left;
  }
}

.region-copyright {
  background: #2d2d2d;
  color: #fff;
  font-size: 12px;
  padding: 10px 0 20px;
}

.region-copyright p {
  margin-bottom: 0;
}

.region-copyright a,
.region-copyright a.active {
  color: #fff;
  text-decoration: none;
}

/*************************************/
.node-type-article .content-wrap {
  border-top: 10px solid #ff6f2a;
  background: #fff;
  margin-bottom: 40px;
}

.node-type-article .content-wrap:before {
  content: '';
  display: table;
}

.node-type-article .content-wrap:after {
  content: '';
  display: table;
  clear: both;
}

@media (min-width: 999px) {
  .node-type-article .content-wrap {
    box-shadow: 10px 10px 0 0 rgba(0, 0, 0, 0.2);
  }
  .node-type-article .content-wrap .layout-3col__right-sidebar {
    margin-top: -56px;
  }
}

.node-type-article .main-wrap main {
  margin: 0 0 45px;
  padding: 20px 25px;
}

@media (min-width: 999px) {
  .node-type-article .main-wrap main {
    padding: 40px 45px 30px;
  }
}

.node-type-article .layout-3col__right-sidebar {
  background-color: #fff;
}

article.node-article.view-mode-full {
  padding-bottom: 45px;
}

article.node-article.view-mode-full .field-name-field-tags {
  padding-top: 20px;
}

.not-front article.node-page.view-mode-full .field-name-body,
.ds-2reg .main-region-bottom {
  border-top: 10px solid #ff6f2a;
  background: #fff;
  margin-bottom: 45px;
  padding: 20px 25px;
}

@media (min-width: 999px) {
  .not-front article.node-page.view-mode-full .field-name-body,
  .ds-2reg .main-region-bottom {
    box-shadow: 10px 10px 0 0 rgba(0, 0, 0, 0.2);
    padding: 40px 45px 30px;
  }
}

.page-node-26 article.node-page.view-mode-full .field-name-body {
  border: 0;
  background: transparent;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
  max-width: 1024px;
}

.region-bottom .block {
  margin: 0 0 45px;
  background: #fff;
  padding: 20px 25px;
}

.region-bottom .block h2.block__title {
  margin-top: 0;
  text-transform: uppercase;
}

@media (min-width: 999px) {
  .region-bottom .block {
    box-shadow: 10px 10px 0 0 rgba(0, 0, 0, 0.2);
    padding: 40px 45px 30px;
  }
}

.front main .view-frontpage {
  padding-bottom: 40px;
}

.front main .view-frontpage .view-content {
  margin: 0 -10px;
}

.front main .views-row {
  background: #fff;
  margin-bottom: 60px;
  position: relative;
  float: left;
  width: 100%;
}

.front main .views-row:hover {
  background: #666666;
  color: #fff;
}

.front main .views-row:hover a {
  color: #fff;
}

.front main .views-row:hover .views-field-field-image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 5px solid #666666;
  box-sizing: border-box;
  pointer-events: none;
}

.front main .views-field-field-image {
  float: left;
  width: 50%;
  margin-right: 10px;
}

.front main .views-field-field-image img {
  width: 100%;
  display: block;
}

.front main .views-field-term-node-tid {
  position: absolute;
  top: 100%;
  left: 0;
  height: 30px;
  overflow: hidden;
}

.front main .views-field-title-1 {
  font-size: 0.95rem;
  line-height: 1.4;
  padding-right: 10px;
  padding-top: 20px;
}

.front main .views-field-title-1 a {
  text-decoration: none;
  display: block;
  color: inherit;
}

.front main .views-field-created {
  font-size: 0.82em;
  color: #999999;
  line-height: 25px;
}

@media (min-width: 666px) {
  .front main .view-frontpage {
    padding-bottom: 60px;
  }
  .front main .view-frontpage .view-content {
    margin: 0;
  }
  .front main .views-row {
    width: 49%;
    margin-right: 2%;
    box-shadow: -10px 10px 0 0 rgba(0, 0, 0, 0.2);
    padding: 0 30px;
  }
  .front main .views-row:nth-child(2n+1) {
    margin-right: 0;
  }
  .front main .views-row:first-child {
    width: 100%;
    margin-right: 0;
  }
  .front main .views-field-field-image {
    float: none;
    width: auto;
    margin: 0 -30px;
    position: relative;
  }
  .front main .views-field-title-1 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  .front main .views-field-title-1 a {
    min-height: 100px;
  }
  .front main .views-field-created {
    line-height: 30px;
    margin-top: 3px;
  }
}

@media (min-width: 999px) {
  .front main {
    margin: 0 45px 0 0;
  }
  .front main .views-row {
    width: auto;
    margin-right: 0;
  }
  .front main .views-row:nth-child(16n+2),
  .front main .views-row:nth-child(16n+7),
  .front main .views-row:nth-child(16n+12) {
    width: 49%;
    margin-right: 2%;
  }
  .front main .views-row:nth-child(16n+3),
  .front main .views-row:nth-child(16n+8),
  .front main .views-row:nth-child(16n+13) {
    width: 49%;
  }
  .front main .views-row:nth-child(16n+4), .front main .views-row:nth-child(16n+5),
  .front main .views-row:nth-child(16n+9), .front main .views-row:nth-child(16n+10),
  .front main .views-row:nth-child(16n+14), .front main .views-row:nth-child(16n+15) {
    width: 32%;
    margin-right: 2%;
  }
  .front main .views-row:nth-child(16n+4) .views-field-title-1 a, .front main .views-row:nth-child(16n+5) .views-field-title-1 a,
  .front main .views-row:nth-child(16n+9) .views-field-title-1 a, .front main .views-row:nth-child(16n+10) .views-field-title-1 a,
  .front main .views-row:nth-child(16n+14) .views-field-title-1 a, .front main .views-row:nth-child(16n+15) .views-field-title-1 a {
    min-height: 150px;
  }
  .front main .views-row:nth-child(16n+6),
  .front main .views-row:nth-child(16n+11),
  .front main .views-row:nth-child(16n) {
    width: 32%;
  }
  .front main .views-row:nth-child(16n+6) .views-field-title-1 a,
  .front main .views-row:nth-child(16n+11) .views-field-title-1 a,
  .front main .views-row:nth-child(16n) .views-field-title-1 a {
    min-height: 150px;
  }
}

.node-article.node-teaser {
  background: #fff;
  margin: 0 -10px 60px;
  position: relative;
  float: left;
}

.node-article.node-teaser:hover {
  background: #666666;
  color: #fff;
}

.node-article.node-teaser:hover a {
  color: #fff;
}

.node-article.node-teaser:hover .views-field-field-image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 5px solid #666666;
  box-sizing: border-box;
  pointer-events: none;
}

.node-article.node-teaser .field-name-field-image {
  float: left;
  width: 50%;
  margin-right: 10px;
}

.node-article.node-teaser .field-name-field-image img {
  width: 100%;
  display: block;
}

.node-article.node-teaser .field-name-field-tags {
  position: absolute;
  top: 100%;
  left: 0;
  height: 30px;
  overflow: hidden;
}

.node-article.node-teaser .field-name-title h3 {
  font-size: 0.95rem;
  line-height: 1.4;
  font-family: "Roboto", sans-serif;
  margin: 0;
  font-weight: normal;
}

.node-article.node-teaser .field-name-title h3 a {
  text-decoration: none;
  display: block;
  color: inherit;
  padding-right: 10px;
}

.node-article.node-teaser .field-name-post-date {
  font-size: 0.82em;
  color: #999999;
  line-height: 25px;
}

@media (min-width: 666px) {
  .node-article.node-teaser {
    box-shadow: 10px 10px 0 0 rgba(0, 0, 0, 0.2);
    padding: 0 30px;
    margin: 0 0 60px;
    width: 100%;
  }
  .node-article.node-teaser .field-name-field-image {
    float: none;
    width: auto;
    margin: 0 -30px;
    position: relative;
  }
  .node-article.node-teaser .field-name-title h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  .node-article.node-teaser .field-name-title h3 a {
    min-height: 100px;
  }
  .node-article.node-teaser .field-name-post-date {
    line-height: 30px;
    margin-top: 3px;
  }
}

.page-taxonomy-term .block-views {
  clear: both;
}

@media (min-width: 666px) {
  .page-taxonomy-term .content-wrap .node-article {
    width: 48%;
    margin-right: 2%;
  }
}

@media (min-width: 999px) {
  .page-taxonomy-term .content-wrap .node-article {
    width: 31.3%;
    margin-right: 2%;
  }
}

.view-videos h2 {
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

.view-videos .node-video-post {
  margin: 10px;
}

.view-articles.view-display-id-block h2 {
  font-size: inherit;
  margin: 0;
  line-height: inherit;
}

.view-articles.view-display-id-block .node-article {
  margin: 10px;
}

.view-articles.view-display-id-block .node-article.view-mode-small_teasers {
  margin: 0 10px 10px 0;
}

.view-videos.view-display-id-block_2,
.view-videos.view-display-id-block_1,
.view-articles.view-display-id-block_1,
.view-search {
  padding-bottom: 60px;
}

.view-videos.view-display-id-block_2:before,
.view-videos.view-display-id-block_1:before,
.view-articles.view-display-id-block_1:before,
.view-search:before {
  content: '';
  display: table;
}

.view-videos.view-display-id-block_2:after,
.view-videos.view-display-id-block_1:after,
.view-articles.view-display-id-block_1:after,
.view-search:after {
  content: '';
  display: table;
  clear: both;
}

@media (min-width: 666px) {
  .view-videos.view-display-id-block_2 .views-row,
  .view-videos.view-display-id-block_1 .views-row,
  .view-articles.view-display-id-block_1 .views-row,
  .view-search .views-row {
    width: 48%;
    margin-right: 2%;
    float: left;
  }
}

@media (min-width: 999px) {
  .view-videos.view-display-id-block_2 .views-row,
  .view-videos.view-display-id-block_1 .views-row,
  .view-articles.view-display-id-block_1 .views-row,
  .view-search .views-row {
    width: 31.3%;
    margin-right: 2%;
    float: left;
  }
}

.view-videos.view-display-id-block .views-row {
  max-width: 200px;
  display: inline-block;
  vertical-align: top;
}

.field-name-field-tags a,
.field-name-field-video-tag a,
.views-field-field-tags a {
  background-color: #ff6f2a;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  margin-right: 10px;
  padding: 3px 5px;
  text-transform: uppercase;
}

.field-name-field-tags .field-item,
.field-name-field-video-tag .field-item,
.views-field-field-tags .field-item {
  display: inline-block;
}

.view-articles.view-display-id-block .field-name-field-tags a, .view-articles.view-display-id-block
.field-name-field-video-tag a, .view-articles.view-display-id-block
.views-field-field-tags a {
  font-size: 11px;
  font-weight: 600;
  line-height: 1.1;
  margin-right: 2px;
  color: #fff;
}

.views-field-term-node-tid a {
  background-color: #ff6f2a;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  margin-right: 10px;
  padding: 3px 5px;
  text-transform: uppercase;
}

.view-articles.view-display-id-block {
  font-size: 14px;
  line-height: 1.3;
  position: relative;
  max-width: 980px;
}

.view-articles.view-display-id-block:before {
  content: '';
  display: table;
}

.view-articles.view-display-id-block:after {
  content: '';
  display: table;
  clear: both;
}

.view-articles.view-display-id-block > .view-header {
  float: left;
  width: 25%;
}

.view-articles.view-display-id-block > .view-footer {
  float: left;
  width: 20%;
}

.view-articles.view-display-id-block h2.block__title {
  margin: 0;
  text-transform: uppercase;
}

.view-articles.view-display-id-block a {
  color: inherit;
  text-decoration: none;
}

.view-articles.view-display-id-block > .view-content {
  width: 55%;
  float: left;
}

.view-articles.view-display-id-block > .view-content .views-row {
  display: block;
  vertical-align: top;
  margin-bottom: 10px;
  padding-right: 25px;
  clear: both;
  overflow: hidden;
}

.view-articles.view-display-id-block > .view-content .views-field-field-image {
  float: left;
  width: 50px;
  margin-right: 10px;
}

.view-articles.view-display-id-block > .view-content .views-field-field-image img {
  max-width: 100%;
  height: auto;
}

.view-articles.view-display-id-block > .view-content .views-field-field-tags {
  display: inline-block;
}

.view-articles.view-display-id-block > .view-content .views-field-field-tags a {
  color: #fff;
}

.view-articles.view-display-id-block > .view-content .views-field-title a {
  text-decoration: none;
  color: inherit !important;
  font-size: 14px;
}

.view-articles.view-display-id-block .more-articles {
  position: absolute;
  left: 25%;
  bottom: 24px;
}

.view-popular a {
  color: inherit;
  text-decoration: none;
  display: block;
}

#block-views-popular-block {
  background: #fff;
  padding: 20px;
}

#block-views-popular-block h2 {
  margin-top: 0;
}

#block-views-popular-block .views-field-field-image,
#block-views-popular-block .views-field-title {
  width: 50%;
  float: left;
}

#block-views-popular-block .views-row {
  clear: both;
  overflow: hidden;
  margin-bottom: 20px;
}

#block-views-popular-block .views-field-title a {
  padding-left: 10px;
}

@media (min-width: 666px) {
  #block-views-popular-block .views-field-field-image,
  #block-views-popular-block .views-field-title {
    width: 100%;
    float: none;
  }
  #block-views-popular-block .views-field-title a {
    padding-left: 0;
  }
  #block-views-popular-block .view-content {
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  #block-views-popular-block .views-row {
    flex: 0 0 50%;
    padding-right: 10px;
    padding-bottom: 20px;
  }
  #block-views-popular-block .views-row a {
    display: block;
  }
  #block-views-popular-block .views-row a img {
    display: block;
    margin-bottom: 10px;
  }
}

@media (min-width: 666px) and (min-width: 999px) {
  #block-views-popular-block .views-row {
    flex: 0 0 100%;
    padding: 0;
  }
}

.view-articles.view-display-id-block_2 a {
  color: inherit;
  text-decoration: none;
  margin-top: 10px;
  display: block;
}

.view-articles.view-display-id-block_2 a img {
  display: block;
}

.view-articles.view-display-id-block_2 .view-content {
  padding-bottom: 40px;
}

.view-articles.view-display-id-block_2 .view-content:before {
  content: '';
  display: table;
}

.view-articles.view-display-id-block_2 .view-content:after {
  content: '';
  display: table;
  clear: both;
}

.view-articles.view-display-id-block_2 .views-field-field-image,
.view-articles.view-display-id-block_2 .views-field-title {
  width: 50%;
  float: left;
}

.view-articles.view-display-id-block_2 .views-row {
  clear: both;
  overflow: hidden;
  margin-bottom: 20px;
}

.view-articles.view-display-id-block_2 .views-field-title a {
  padding-left: 10px;
}

@media (min-width: 666px) {
  .view-articles.view-display-id-block_2 .views-field-field-image,
  .view-articles.view-display-id-block_2 .views-field-title {
    width: 100%;
    float: none;
  }
  .view-articles.view-display-id-block_2 .views-field-title a {
    padding-left: 0;
  }
  .view-articles.view-display-id-block_2 .views-row {
    width: 48%;
    margin-right: 2%;
    float: left;
    clear: none;
  }
  .view-articles.view-display-id-block_2 .views-row:nth-child(2n+1) {
    clear: both;
  }
}

@media (min-width: 999px) {
  .view-articles.view-display-id-block_2 {
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .view-articles.view-display-id-block_2 .views-row {
    margin-bottom: 25px;
    float: none;
    width: auto;
    margin-right: 0;
  }
}

.view-popular.view-display-id-block_1 .view-content {
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.view-popular.view-display-id-block_1 .views-field-field-image,
.view-popular.view-display-id-block_1 .views-field-title {
  width: 50%;
  float: left;
}

.view-popular.view-display-id-block_1 .views-field-title a {
  padding-left: 10px;
}

.view-popular.view-display-id-block_1 .views-row {
  flex: 0 0 100%;
  padding-bottom: 20px;
  clear: both;
  overflow: hidden;
}

.view-popular.view-display-id-block_1 .views-row a {
  display: block;
}

.view-popular.view-display-id-block_1 .views-row a img {
  display: block;
  margin-bottom: 10px;
}

@media (min-width: 666px) {
  .view-popular.view-display-id-block_1 .views-row {
    flex: 0 0 50%;
    padding-right: 10px;
  }
  .view-popular.view-display-id-block_1 .views-row .views-field-field-image,
  .view-popular.view-display-id-block_1 .views-row .views-field-title {
    width: 100%;
    float: none;
  }
  .view-popular.view-display-id-block_1 .views-row .views-field-title a {
    padding-left: 0;
  }
}

@media (min-width: 999px) {
  .view-popular.view-display-id-block_1 .views-row {
    flex: 0 0 33.3%;
    padding-right: 10px;
  }
}

.not-front .layout-3col__right-sidebar .block__title {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 1.4rem;
}

@media (min-width: 999px) {
  .not-front #block-views-articles-block-2 .block__title {
    padding-left: 30px;
  }
}

.view-categories.view-display-id-block_1 img,
.view-categories.view-display-id-block_4 img {
  display: inline-block;
  width: 50px;
  margin-right: 7px;
}

.view-categories.view-display-id-block_1 a,
.view-categories.view-display-id-block_4 a {
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: inherit;
}

.view-categories.view-display-id-block_1 li,
.view-categories.view-display-id-block_4 li {
  min-height: 55px;
  display: block;
  font-size: 14px;
}

.view-categories.view-display-id-block_1 ul,
.view-categories.view-display-id-block_4 ul {
  padding: 0;
  margin: 0 0 10px;
}

.view-categories.view-display-id-block_4 > .view-header {
  float: left;
  width: 30%;
}

.view-categories.view-display-id-block_4 > .view-header > .view {
  margin-right: 30px;
}

.view-categories.view-display-id-block_4 > .view-header img {
  display: block;
  width: 100%;
  margin-right: 0;
}

.view-categories.view-display-id-block_4 > .view-content {
  float: left;
  width: 70%;
}

.view-categories.view-display-id-block_4 > .view-content ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 900px;
}

.view-categories.view-display-id-block_4 > .view-content ul li {
  flex: 0 0 33%;
}

.view-categories.view-display-id-block_3 ul,
.view-other-tax ul {
  list-style: none;
  padding: 12px 0 20px;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.view-categories.view-display-id-block_3 li,
.view-other-tax li {
  flex: 0 0 33.3%;
  padding-right: 10px;
  padding-bottom: 10px;
  max-width: 150px;
  min-width: 80px;
}

.view-categories.view-display-id-block_3 li a,
.view-other-tax li a {
  display: block;
}

.view-categories.view-display-id-block_3 li a img,
.view-other-tax li a img {
  display: block;
}

@media (min-width: 999px) {
  .view-categories.view-display-id-block_3 li,
  .view-other-tax li {
    padding-right: 4px;
    padding-bottom: 4px;
  }
}

.view-categories.view-display-id-block_3 ul {
  padding: 12px 0 20px;
}

.feed-icon {
  float: right;
  clear: both;
}

.view-categories.view-display-id-block_2 .views-row {
  margin-bottom: 20px;
}

.view-categories.view-display-id-block_2 ul {
  padding: 0;
  margin: 0;
  display: block;
  clear: both;
  overflow: hidden;
}

.view-categories.view-display-id-block_2 .views-row {
  display: inline-block;
  vertical-align: top;
}

.view-categories.view-display-id-block_2 .views-row a {
  color: inherit;
  text-decoration: none;
  display: block;
}

@media (min-width: 666px) {
  .view-categories.view-display-id-block_2 .views-row {
    width: 48%;
    margin-right: 2%;
    float: left;
  }
}

@media (min-width: 999px) {
  .view-categories.view-display-id-block_2 .views-row {
    width: 31.3%;
    margin-right: 2%;
    float: left;
  }
}

.view-categories.view-display-id-block_2 {
  padding-bottom: 60px;
}

@media (min-width: 888px) {
  .front .layout-3col__right-sidebar {
    padding-top: 20vh;
  }
}

.front .layout-3col__right-sidebar #block-views-categories-block-3 .block__title {
  display: none;
}

#block-block-1--2 li {
  display: inline-block;
  margin-left: 5px;
}

.in-maintenance .main-content {
  padding: 30px;
}

.view-author-details {
  position: relative;
  padding-top: 12px;
}

.view-author-details a {
  text-decoration: none;
}

.view-author-details .views-field-name {
  font-size: 1.2rem;
  font-weight: bold;
}

.view-author-details .views-field-name a span {
  font-size: 1rem;
  font-weight: normal;
  color: #333;
}

.view-author-details .views-field-field-author-image {
  width: 70px;
  height: 70px;
  float: left;
  margin-right: 12px;
}

.view-author-details .views-field-field-author-image img {
  display: block;
}

.view-author-details .views-field-field-twitter {
  padding-top: 1.5rem;
}

.view-author-details .view-footer {
  position: absolute;
  left: 82px;
  bottom: 24px;
}

#disqus_thread {
  margin: 50px 0 20px;
}

.video_background {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: 60vh;
  overflow: hidden;
  z-index: -100;
  transition: 1s opacity;
}

.video_background video {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  background-size: cover;
}

@media (min-width: 666px) {
  .video_background {
    height: 0;
    padding-bottom: 56.25%;
  }
  .video_background video {
    width: 100%;
  }
}

.views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 0;
}

.btn-header-search {
  float: right;
  margin: 10px 12px 0 40px;
  position: relative;
}

.btn-header-search a {
  transition: color .3s ease-out;
  color: #fff;
  font-size: 22px;
  line-height: 48px;
  display: block;
  padding-left: 1px;
}

.btn-header-search a:hover {
  color: #ff6f2a;
}

#block-block-1 ul,
#block-block-3 ul {
  margin: 0;
}

#block-block-1 li,
#block-block-3 li {
  display: inline-block;
  margin: 0 4px 0 0;
  vertical-align: middle;
}

#block-block-1 li a,
#block-block-3 li a {
  transition: color .3s ease-out;
  color: #fff;
  font-size: 22px;
}

#block-block-1 li a:hover,
#block-block-3 li a:hover {
  color: #ff6f2a;
}

@media (min-width: 888px) {
  #block-block-1,
  #block-block-3 {
    text-align: right;
  }
}

#block-block-3 {
  text-align: right;
}

#block-block-3 ul {
  padding: 5px 10px 0 0;
}

#block-block-3 ul a {
  line-height: 48px;
}

@media (min-width: 888px) {
  #block-block-3 ul {
    padding: 0;
    width: 30px;
    height: 50px;
  }
}

@media (min-width: 888px) {
  #block-block-3 {
    float: right;
    margin-left: 0;
  }
  #block-block-3 ul li a {
    color: #999;
  }
  #block-block-3 .icon-instagram,
  #block-block-3 .icon-linkedin,
  #block-block-3 .icon-twitter,
  #block-block-3 .icon-youtube {
    display: none;
  }
}

#block-views-exp-search-page {
  margin: 0;
  position: absolute;
  bottom: -81px;
  left: 0;
  right: 0;
}

#block-views-exp-search-page form {
  max-width: 1213px;
  margin: 0 auto;
  text-align: right;
}

#block-views-exp-search-page form > div {
  background: #fff;
  padding: 20px;
  display: inline-block;
}

#block-views-exp-search-page .form-text {
  width: 170px;
  padding: 5px 3px 3px;
}

#block-views-exp-search-page .views-exposed-widget {
  padding: 0;
}

#block-views-exp-search-page .views-exposed-widgets {
  margin-bottom: 0;
}

#block-views-exp-search-page .views-submit-button input {
  border: 0;
  background: #ff6f2a url(../images/ico-search.svg) center center no-repeat;
  background-size: 26px 26px;
  text-indent: 40px;
  width: 38px;
  height: 34px;
  overflow: hidden;
}

@media (min-width: 999px) {
  #block-views-exp-search-page {
    bottom: auto;
    top: 60px;
  }
}

.addtoany_list {
  padding: 20px 0 0;
  display: block;
}

.addtoany_list a span {
  width: 37px !important;
  height: 37px !important;
  border-radius: 50% !important;
}

.addtoany_list .a2a_svg svg {
  top: 6% !important;
  width: 86% !important;
}

@media only screen and (max-width: 888px) {
  article.node-page.view-mode-full .field-name-body figure.image {
    float: none !important;
    margin: 0;
  }
}

#sliding-popup .popup-content {
  max-width: 100%;
  padding: 0 12px;
}

#sliding-popup .popup-content #popup-text {
  font-size: 13px;
  font-weight: normal;
}

#sliding-popup .popup-content #popup-text p {
  font-size: 13px;
  font-weight: normal;
}

#sliding-popup .eu-cookie-compliance-secondary-button {
  font-size: 14px;
}

#sliding-popup .eu-cookie-compliance-default-button {
  font-size: 14px;
  border-radius: 0;
}

/*
.eu-cookie-compliance-processed{
	padding-bottom: 70px;
}*/
