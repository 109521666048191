// Font faces
//
// Instead of relying on the fonts that are available on a user's computer, you
// can use web fonts which, like images, are resources downloaded to the user's
// browser. Because of the bandwidth and rendering resources required, web fonts
// should be used with care.
//
// Numerous resources for web fonts can be found on Google. Here are a few
// websites where you can find Open Source fonts to download:
// - http://www.fontsquirrel.com/fontface
// - http://www.theleagueofmoveabletype.com
//
// In order to use these fonts, you will need to convert them into formats
// suitable for web fonts. We recommend the free-to-use Font Squirrel's
// Font-Face Generator:
//   http://www.fontsquirrel.com/fontface/generator
//
// The following is an example @font-face declaration. This font can then be
// used in any ruleset using a property like this:  font-family: Example, serif;
//
// Since we're using Sass, you'll need to declare your font faces here, then you
// can add them to the font variables in the _init.scss partial.

// @font-face {
//   font-family: 'Example';
//   src: url('../fonts/example.eot');
//   src: url('../fonts/example.eot?iefix') format('eot'),
//     url('../fonts/example.woff') format('woff'),
//     url('../fonts/example.ttf') format('truetype'),
//     url('../fonts/example.svg#webfontOkOndcij') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/icomoon.eot?x9jvmv');
    src:    url('../fonts/icomoon.eot?x9jvmv#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?x9jvmv') format('truetype'),
        url('../fonts/icomoon.woff?x9jvmv') format('woff'),
        url('../fonts/icomoon.svg?x9jvmv#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 44px;
    font-size: 32px;
    width: 32px;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;

    /* Enable Ligatures ================ */
    letter-spacing: 0;
    -webkit-font-feature-settings: 'liga';
    -moz-font-feature-settings: 'liga=1';
    -moz-font-feature-settings: 'liga';
    -ms-font-feature-settings: 'liga' 1;
    -o-font-feature-settings: 'liga';
    font-feature-settings: 'liga';

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a[class^='icon-'],
a[class*=' icon-'] {
	text-decoration: none;

	&:before {
		margin-right: 50px;
        width: 1.2em;
        display: inline-block;
	}
}

.icon-google {
	width: 44px;
}

.icon-checkmark:before {
    content: '\ea10';
}

.icon-circle-up:before {
    content: '\ea41';
}

.icon-circle-right:before {
    content: '\ea42';
}

.icon-circle-down:before {
    content: '\ea43';
}

.icon-circle-left:before {
    content: '\ea44';
}

.icon-mail4:before {
    content: '\ea86';
}

.icon-google-plus:before {
    content: '\ea8b';
}

.icon-facebook:before {
    content: '\ea90';
}

.icon-instagram:before {
    content: '\ea92';
}

.icon-twitter:before {
    content: '\ea96';
}

.icon-rss2:before {
    content: '\ea9c';
}

.icon-youtube:before {
    content: '\ea9d';
}

.icon-vimeo2:before {
    content: '\eaa1';
}

.icon-flickr3:before {
    content: '\eaa5';
}

.icon-tumblr:before {
    content: '\eab9';
}

.icon-skype:before {
    content: '\eac5';
}

.icon-linkedin:before {
    content: '\eac9';
}

.icon-pinterest:before {
    content: '\ead1';
}

.icon-xing:before {
    content: '\ead3';
}

.icon-search:before {
    content: "\e986";
}