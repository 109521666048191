// HTML5 display definitions
// ==========================================================================

// Correct `block` display not defined for any HTML5 element in IE 8/9.
// Correct `block` display not defined for `details` or `summary` in IE 10/11
// and Firefox.
// Correct `block` display not defined for `main` in IE 11.
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  @if support-for(ie, 9) {
    // Correct `inline-block` display not defined in IE 8/9.
    display: inline-block;
  }
  // Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
  vertical-align: baseline;
}

// Prevent modern browsers from displaying `audio` without controls.
audio:not([controls]) {
  display: none;
  // Remove excess height in iOS 5 devices.
  height: 0;
}

@if support-for(ie, 10) {
  // Address `[hidden]` styling not present in IE 8/9/10.
  [hidden] {
    display: none;
  }
}

// Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22.
template {
  display: none;
}
