.header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 110;

    .in-maintenance & {
    	position: static;
    }

	&:before {
	    height: 60px;
	    left: 0;
	    position: absolute;
	    top: 0;
	    width: 100%;
	    background: color('nav-grey');
	}

	.layout-center {
		position: static;
    	height: 100%;
    	padding: 0;
	}

	.block > .menu {
		margin: 0;
		padding: 0;

		> .menu__item.mega-dropdown > .menu__link:before,
		> .menu__item.expanded > .menu__link:before {
		    width: 0;
		    height: 0;
		    border-style: solid;
		    border-width: 7px 5px 0;
		    border-color: color('white') transparent transparent;
		    content: '';
		    position: absolute;
		    right: 5px;
		    top: 20px;
		}
			
		> .menu__item.mega-dropdown > .menu__link:before {
			display: none;
		}

		.menu-attach-block-drop-link {
		    display: none;
		}

	}

	*:focus,
	*:active {
		outline: 0 none;
	}

	.admin-menu &{
		top: 29px;
	}

	@include zen-respond-to(l) {
		.header__region{
			>.block-search,
			>.block-block{
				z-index: 111;
		    	position: relative;
			}
		}
	}
}

.header__logo {
    position: relative;
    z-index: 50;

    img{
    	width: 124px;
    }
}

.region-header {
	margin: 0;
	clear: both;
	background: color('nav-grey');
	display: none;
	position: relative;

	>.block {

		> .menu > .menu__item {
			list-style: none;
			display: block;
			padding: 0 10px 0 10px;
			
			> .menu__link {
				@include typeface(headings);
				text-decoration: none;
				text-transform: uppercase;
				color: color('white');
				padding: 14px 8px 0 8px;
				font-size: 14px;
			    display: block;
			    height: 50px;
			    position: relative;
			    border-bottom: 1px dotted color('grey-light');

				.is-active-trail {
    				border-left: 5px color('orange') solid;
				}
			}

			&.mega-dropdown > .menu__link,
			&.is-expanded > .menu__link {
				padding-right: 25px;
			}

			.menu{
				display: none;
			}

			.menu__item{
				padding:13px 10px;
				list-style: none;
				display: block;
				border-bottom: 1px dotted color('grey-light');

				a{
					@include typeface(headings);
					text-decoration: none;
					text-transform: uppercase;
					color: color('white');
					font-size: 14px;
				}
			}

			&.active {
				.menu {
					display: block;
				}
				> .menu__link {
					background: color('white');
					color: color('black');
				}
			}
		}
	}
}

.mobile-menu-btn {
    background: none;
    border: 0 none;
    height: 60px;
    padding: 0;
    width: 60px;
    float: left;
    color: #fff;
    position: relative;
    z-index: 60;   
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;

    .line {
    	background-color: color('white');
    	height: 3px;
    	display: block;
    	position: absolute;
    	left: 16px;
    	right: 16px;
    	opacity: 1;
    	-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .25s ease-in-out;
		-moz-transition: .25s ease-in-out;
		-o-transition: .25s ease-in-out;
		transition: .25s ease-in-out;
    }
}

.mobile-menu-btn span:nth-child(1) {
  top: 22px;
}

.mobile-menu-btn span:nth-child(2) {
  top: 29px;
}

.mobile-menu-btn span:nth-child(3) {
  top: 36px;
}

.mobile-menu-active {
	.header {
    	height: 100%;
	}
	.region-header {
		display: block;
	}
	.mobile-menu-btn{
		span:nth-child(1) {
		  top: 30px;
		  -webkit-transform: rotate(135deg);
		  -moz-transform: rotate(135deg);
		  -o-transform: rotate(135deg);
		  transform: rotate(135deg);
		}

		span:nth-child(2) {
		  opacity: 0;
		  left: -60px;
		}

		span:nth-child(3) {
		  top: 30px;
		  -webkit-transform: rotate(-135deg);
		  -moz-transform: rotate(-135deg);
		  -o-transform: rotate(-135deg);
		  transform: rotate(-135deg);
		}
	}
}


@include zen-respond-to(l) {

	.region-header {
		clear: none;
		background: transparent;
		display: block;

		>.block {
			float: left;
			margin: 10px 0 0 0;

			> .menu > .menu__item {
				display: inline-block;				
				padding: 0 0 0 8px;

				> .menu__link {
					padding-top: 10px;
					border: 0;
				}

				.menu__item {
					padding: 0;
					border: 0;
				}
			}
		}
	}

	a.menu-attach-block-drop-link ~ .menu-attach-block-wrapper,
	.header .block > .menu > .menu__item.mega-dropdown > .menu__link:before {
		display: block;
	}

	.mobile-menu-btn{
		display: none
	}

	.header .block > .menu > .menu__item.mega-dropdown.active > .menu__link:before {
		border-color: color('black') transparent transparent;
	}

	.menu-attach-block-wrapper,
	.header .block .menu__item.is-expanded>.menu {
		background: #fff;
		left: 0;
		position: absolute !important;
		width: 100%;
		box-shadow: 0 3px 0 0 rgba(0, 0, 0, .2);
		padding: 20px 15px;
		top: -1000px;
		z-index: -1;
		opacity: .5;
		transition: ease-in-out 1s top, ease-in-out 1s opacity;

	  li {
	  	list-style: none;
	  	background: none;
	  }
	}

	.header {

		.block > .menu {

			> .menu__item.active > .menu-attach-block-wrapper,
			> .menu__item.active > .menu {
				top: 60px !important;
				opacity: 1 !important;
			}
		}

	}
		
	.menu-attach-block-wrapper {
		h2 {
			font-size: 1.3em;
			margin:10px 0;
		}
	}

	.menu-attach-block-wrapper.orientation-horizontal .block {
		overflow: visible;
		background: none;
		padding: 0;
		margin: 0 auto;
		max-width: 1213px;
		position: static;
	}

	.header .block .menu__item.is-expanded>.menu {
		background: color('grey');
		left: auto;
		width: 220px;

		a {
			color: color('white');
			text-decoration: none;
		}
	}
}

/*************************************/
.region-footer {
	background: color('nav-grey');
	color: color('white');
	padding: 30px 0 10px;
	@include clearfix();
	font-size: 13px;

	p{
		margin-bottom: 10px;
	}

	p.brand{
		@include respond-to(xl) {
			margin-bottom: 40px;
		}
	}

	a,
	a.active {
		color: color('white');
		text-decoration: none;
	}

	:link,
	:visited {
		color: color('white');
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			list-style: none;
			background: none;
			line-height: 1.8;
		}
	}

	.block {
		width: 100%;
		display: block;
		vertical-align: top;

		.menu__item a {
			text-transform: uppercase;
			text-decoration: none;
			line-height: 2em;
		}	

		@include respond-to(xl) {
			width: 33.3%;
			float: left;
		}
	}

}

.region-copyright {
	background: color('nav-grey');
	color: color('white');
	font-size: 12px;
	padding:10px 0 20px;

	p{
		margin-bottom: 0;
	}

	a,
	a.active {
		color: color('white');
		text-decoration: none;
	}
}

/*************************************/
.node-type-article{
	.content-wrap{
		@include clearfix();
		border-top: 10px solid color('orange');
		background: color('white');
		margin-bottom: 40px;

		@include respond-to(xl) {
	  		box-shadow: 10px 10px 0 0 rgba(0,0,0,0.2);

	  		.layout-3col__right-sidebar {
	  			margin-top: -56px;
	  		}
	  	}
	}

	.main-wrap{
	  main {
	  	margin: 0 0 45px;	
	  	padding: 20px 25px;
	  	
	  	@include respond-to(xl) {
	  		padding: 40px 45px 30px;
	  	}
	  }
	}

	.layout-3col__right-sidebar{
		background-color: #fff;
	}
}

article.node-article.view-mode-full{
	padding-bottom: 45px;

	.field-name-field-tags{
		padding-top: 20px;
	}
}

.not-front article.node-page.view-mode-full .field-name-body,
.ds-2reg .main-region-bottom{
	border-top: 10px solid color('orange');
	background: color('white');
	margin-bottom: 45px;
	padding: 20px 25px;

	@include respond-to(xl) {
  		box-shadow: 10px 10px 0 0 rgba(0,0,0,0.2);
  		padding: 40px 45px 30px;
  	}
}

.page-node-26 article.node-page.view-mode-full .field-name-body{ // intro text sectors page
	border: 0;
	background: transparent;
	box-shadow: none;
	padding-top: 0;
	padding-bottom: 0;
	max-width: 1024px;
}

.region-bottom {
  .block  {
  	margin: 0 0 45px;
  	background: color('white');
  	padding: 20px 25px;


  	h2.block__title{
  		margin-top: 0;
  		text-transform: uppercase;
  	}
  	
  	@include respond-to(xl) {
  		box-shadow: 10px 10px 0 0 rgba(0,0,0,0.2);
  		padding: 40px 45px 30px;
  	}
  }
}

.front main{
	.view-frontpage{
		padding-bottom: 40px;

		.view-content{
			margin: 0 -10px; //adjustment for mobile layout
		}
	}
	.views-row{
		background: color('white');
		margin-bottom: 60px;
		position: relative;
		float: left;
		width: 100%;

		&:hover{
			background: color('grey-dark');
			color: #fff;
			a{
				color: #fff
			}
			.views-field-field-image:after{
				content: "";
			    position: absolute;
			    top: 0;
			    left: 0;
			    width: 100%;
			    height: 100%;
			    border: 5px solid color('grey-dark');
			    box-sizing: border-box;
			    pointer-events: none;
			}
		}
	}

	.views-field-field-image{
		float: left;
		width: 50%;
		margin-right: 10px;

		img{
			width: 100%;
			display: block;
		}
	}

	.views-field-term-node-tid{
		position: absolute;
		top: 100%;
		left: 0;
		height: 30px;
		overflow: hidden;
	}

	.views-field-title-1{
		font-size: 0.95rem;
		line-height: 1.4;
		padding-right: 10px;
		padding-top: 20px;

		a{
			text-decoration: none; 
			display: block;
			color:inherit;
		}
	}

	.views-field-created{
		font-size: 0.82em;
		color: color('grey');
		line-height: 25px;
	}

	@include zen-respond-to(s) { // tablets
		.view-frontpage{
			padding-bottom: 60px;

			.view-content{
				margin: 0;
			}
		}

		.views-row{
			width: 49%;	
			margin-right: 2%;
			box-shadow: -10px 10px 0 0 rgba(0,0,0,0.2);
			padding: 0 30px;

			&:nth-child(2n+1){
				margin-right: 0;
			}

			&:first-child{
				width: 100%;
				margin-right: 0;
			}
		}

		.views-field-field-image{
			float: none;
			width: auto;
			margin: 0 -30px;
			position: relative;
		}

		.views-field-title-1{
		    @include type-layout(l, 1);
			a{
				min-height: 100px;
			}
		}

		.views-field-created{
			line-height: 30px;
			margin-top: 3px;
		}
	}

	@include zen-respond-to(xl) { // desktop
		margin: 0 45px 0 0;

		.views-row{
			width: auto;
			margin-right: 0;
		}

		.views-row:nth-child(16n+2),
		.views-row:nth-child(16n+7),
		.views-row:nth-child(16n+12){
			width: 49%;	
			margin-right: 2%;		
		}		
	    .views-row:nth-child(16n+3),
	    .views-row:nth-child(16n+8),
	    .views-row:nth-child(16n+13){
			width: 49%;			
		}
		.views-row:nth-child(16n+4),.views-row:nth-child(16n+5),
		.views-row:nth-child(16n+9),.views-row:nth-child(16n+10),
		.views-row:nth-child(16n+14),.views-row:nth-child(16n+15){
			width: 32%;
			margin-right: 2%;

			.views-field-title-1 a{
				min-height: 150px;
			}		
		}
		.views-row:nth-child(16n+6),
		.views-row:nth-child(16n+11),
		.views-row:nth-child(16n){
			width: 32%;

			.views-field-title-1 a{
				min-height: 150px;
			}	
		}		
	}
}

.node-article.node-teaser{
	background: color('white');
	margin: 0 -10px 60px;
	position: relative;
	float: left;

	&:hover{
		background: color('grey-dark');
		color: #fff;
		a{
			color: #fff
		}
		.views-field-field-image:after{
			content: "";
		    position: absolute;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    border: 5px solid color('grey-dark');
		    box-sizing: border-box;
		    pointer-events: none;
		}
	}

	.field-name-field-image{
		float: left;
		width: 50%;
		margin-right: 10px;

		img{
			width: 100%;
			display: block;
		}
	}

	.field-name-field-tags{
		position: absolute;
		top: 100%;
		left: 0;
		height: 30px;
		overflow: hidden;
	}

	.field-name-title{
		h3{
			font-size: 0.95rem;
			line-height: 1.4;
		    @include typeface(body);
		    margin: 0;
		    font-weight: normal;

			a{
				text-decoration: none; 
				display: block;
				color:inherit;
				padding-right: 10px;
			}
		}
	}

	.field-name-post-date{
		font-size: 0.82em;
		color: color('grey');
		line-height: 25px;
	}

	@include zen-respond-to(s) {
		box-shadow: 10px 10px 0 0 rgba(0,0,0,0.2);
		padding: 0 30px;
		margin: 0 0 60px;
		width: 100%;

		.field-name-field-image{
			float: none;
			width: auto;
			margin: 0 -30px;
			position: relative;
		}

		.field-name-title h3{
		    @include type-layout(l, 1);
			a{
				min-height: 100px;
			}
		}

		.field-name-post-date{
			line-height: 30px;
			margin-top: 3px;
		}
	}
}

.page-taxonomy-term{
	.block-views{
		clear: both;
	}

	@include zen-respond-to(s) { //tablets
		.content-wrap .node-article {
			width: 48%;
			margin-right: 2%;
		}
	}

	@include zen-respond-to(xl) {
		.content-wrap .node-article{
			width: 31.3%;
			margin-right: 2%;		
		}
	}
}

.view-videos {
	h2 {
		font-size: inherit;
		line-height: inherit;
		margin:0;
	}

	.node-video-post {
		margin: 10px;
	}
}

.view-articles.view-display-id-block{
	h2 {
		font-size: inherit;
		margin:0;
		line-height: inherit;
	}

	.node-article {
		margin: 10px;

		&.view-mode-small_teasers{
			margin: 0 10px 10px 0;
		}
	}
}

.view-videos.view-display-id-block_2,
.view-videos.view-display-id-block_1,
.view-articles.view-display-id-block_1,
.view-search {
	//@include zen-grid-container($context: flow);
	padding-bottom: 60px;
	@include clearfix();

	@include zen-respond-to(s) { //tablets
		.views-row {
			width: 48%;
			margin-right: 2%;
			float: left;
		}
	}

	@include zen-respond-to(xl) {
		.views-row {
			width: 31.3%;
			margin-right: 2%;
			float: left;
		}
	}
}

.view-videos.view-display-id-block {
	.views-row {
		max-width: 200px;
		display: inline-block;
		vertical-align: top;
	}
}

.field-name-field-tags,
.field-name-field-video-tag,
.views-field-field-tags {
	a {
		background-color: color('orange');
		display: inline-block;
		color: color('white');
		text-decoration: none;
		margin-right: 10px;
		padding: 3px 5px;
		text-transform: uppercase;
	}

	.field-item{
		display: inline-block;
	}

	.view-articles.view-display-id-block & {
		a {
			font-size: 11px;
			font-weight: 600;
			line-height: 1.1;
			margin-right: 2px;
			color: #fff;
		}
	}
}

.views-field-term-node-tid{
	a {
		background-color: color('orange');
		display: inline-block;
		color: color('white');
		text-decoration: none;
		margin-right: 10px;
		padding: 3px 5px;
		text-transform: uppercase;
	}	
}

.view-articles.view-display-id-block { // articles listing in navbar (attached block)
	font-size: 14px;
	line-height: 1.3;
	position: relative;
	max-width: 980px;
	@include clearfix();

	>.view-header{
		float: left;
		width: 25%;
	}

	>.view-footer{
		float: left;
		width: 20%;
	}

	h2.block__title{
			margin: 0;
			text-transform: uppercase;
	}

	a{
		color: inherit;
		text-decoration: none;
	}

	>.view-content{
		width: 55%;
		float: left;

		.views-row {
			display: block;
			vertical-align: top;
			margin-bottom: 10px;
			padding-right: 25px;
			clear: both;
			overflow: hidden;
		}

		.views-field-field-image {
			float: left;
			width: 50px;
			margin-right: 10px;
			
			img {
				max-width: 100%;
				height: auto;
			}
		}

		.views-field-field-tags {
			display: inline-block;
			a{
				color: #fff;
			}
		}

		.views-field-title {
			a{
				text-decoration: none;
				color: inherit!important;
				font-size: 14px;
			}
		}
	}

	.more-articles {
		position: absolute;
		left: 25%;
		bottom: 24px;
	}
}

.view-popular{
	a{
		color: inherit;
		text-decoration: none;
		display: block;
	}
}

#block-views-popular-block{ // homepage popular articles
	background:#fff;
	padding:20px;

	h2{
		margin-top: 0;
	}

	.views-field-field-image,
	.views-field-title {
		width: 50%;
		float: left;
	}

	.views-row{
		clear: both;
		overflow: hidden;
		margin-bottom: 20px;
	}

	.views-field-title a{
		padding-left: 10px;
	}

	@include zen-respond-to(s) {
		.views-field-field-image,
		.views-field-title {
			width: 100%;
			float: none;
		}

		.views-field-title a{
			padding-left: 0;
		}

		.view-content{
			margin: 0;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}

		.views-row{
			flex: 0 0 50%;
	    	padding-right: 10px;
	    	padding-bottom: 20px;

	    	a{
	    		display: block;

	    		img{
	    			display: block;
	    			margin-bottom: 10px;
	    		}
	    	}
		}

		@include zen-respond-to(xl) {
			.views-row{
				flex: 0 0 100%;
				padding: 0;
			}
		}
	}
}

.view-articles.view-display-id-block_2{  // latest articles - sidebar
    a{
    	color: inherit;
    	text-decoration: none;
    	margin-top: 10px;
    	display: block;

    	img{
    		display: block;
    	}
    }

    .view-content {
    	@include clearfix();
    	padding-bottom: 40px;
    }

    .views-field-field-image,
	.views-field-title {
		width: 50%;
		float: left;
	}

	.views-row{
		clear: both;
		overflow: hidden;
		margin-bottom: 20px;
	}

	.views-field-title a{
		padding-left: 10px;
	}

    @include zen-respond-to(s) { // tablets
    	.views-field-field-image,
		.views-field-title {
			width: 100%;
			float: none;
		}

		.views-field-title a{
			padding-left: 0;
		}

    	.views-row{
			width: 48%;
			margin-right: 2%;
			float: left;
			clear: none;

			&:nth-child(2n+1){
				clear: both;
			}
		}
    }

    @include zen-respond-to(xl) { // desktop
    	font-size: 18px;
	    padding-left: 30px;
	    padding-right: 30px;

	    .views-row{
    		margin-bottom: 25px;
    		float: none;
    		width: auto;
    		margin-right: 0;
    	}
    }
}

.view-popular.view-display-id-block_1{ // popular view - bottom of the articles
	.view-content{
		margin: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.views-field-field-image,
	.views-field-title {
		width: 50%;
		float: left;
	}

	.views-field-title a{
		padding-left: 10px;
	}

	.views-row{
		flex: 0 0 100%;
    	padding-bottom: 20px;
    	clear: both;
		overflow: hidden;

    	a{
    		display: block;

    		img{
    			display: block;
    			margin-bottom: 10px;
    		}
    	}

    	@include zen-respond-to(s) { //tablets
    		flex: 0 0 50%;
    		padding-right: 10px;

	    	.views-field-field-image,
			.views-field-title {
				width: 100%;
				float: none;
			}

			.views-field-title a{
				padding-left: 0;
			}
		}

    	@include zen-respond-to(xl) { // desktop
    		flex: 0 0 33.3%;
    		padding-right: 10px;
		}		
	}
}

.not-front .layout-3col__right-sidebar .block__title{
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 1.4rem;
}

.not-front #block-views-articles-block-2 .block__title{
	@include zen-respond-to(xl) { // desktop
    	padding-left: 30px;
	}
}

.view-categories.view-display-id-block_1,
.view-categories.view-display-id-block_4{
	img{
		display: inline-block;
		width: 50px;
		margin-right: 7px;
	}
	a{
		display: inline-block;
		vertical-align: top;
		text-decoration: none;
		color: inherit;
	}
	li{
		min-height: 55px;
		display: block;
		font-size: 14px;
	}
	ul{
		padding: 0;
		margin: 0 0 10px;
	}
}

.view-categories.view-display-id-block_4{ // main menu attached block (desktop)
	>.view-header{
		float: left;
		width: 30%;

		>.view{
			margin-right: 30px;
		}

		img{
			display: block;
			width: 100%;
			margin-right: 0;
		}
	}
	>.view-content{
		float: left;
		width: 70%;

		ul{
			list-style: none;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			max-width: 900px;

			li{
				flex: 0 0 33%;
			}
		}
	}
}

.view-categories.view-display-id-block_3,
.view-other-tax{ // small icons taxonomy list
	ul{
		list-style: none;
		padding: 12px 0 20px;
		margin: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
    li{
    	flex: 0 0 33.3%;
    	padding-right: 10px;
    	padding-bottom: 10px;
    	max-width: 150px;
    	min-width: 80px;

    	a{
    		display: block;

    		img{
    			display: block;
    		}
    	}

    	@include zen-respond-to(xl) { // desktop
			padding-right: 4px;
    		padding-bottom: 4px;
    	}
    }
}

.view-categories.view-display-id-block_3{ 
	ul{
		padding: 12px 0 20px;
	}
}

.feed-icon {
	float: right;
	clear: both;
}

.view-categories.view-display-id-block_2{ // sectors page
	.views-row {
		margin-bottom: 20px;
	}

	ul{
		padding: 0;
		margin: 0;
		display: block;
		clear: both;
		overflow: hidden;
	}

	.views-row {
		display: inline-block;
		vertical-align: top;

		a{
			color: inherit;
			text-decoration: none;
			display: block;
		}
	}

	@include zen-respond-to(s) { //tablets
		.views-row {
			width: 48%;
			margin-right: 2%;
			float: left;
		}
	}

	@include zen-respond-to(xl) { // desktop
		.views-row {
			width: 31.3%;
			margin-right: 2%;
			float: left;
		}
	}
}

.view-categories.view-display-id-block_2 {
	padding-bottom: 60px;
}

.front .layout-3col__right-sidebar {
	@include zen-respond-to(l) {
		padding-top: 20vh;
	}

	#block-views-categories-block-3 .block__title{
		display: none;
	}
}

#block-block-1--2{
	li{
		display: inline-block;
		margin-left: 5px;
	}
}

.in-maintenance .main-content{
	padding: 30px;
}


.view-author-details{
	position: relative;
	padding-top: 12px;

	a{
		text-decoration: none;
	}
	.views-field-name{
		font-size: 1.2rem;
	    font-weight: bold;
	    a span{
		    font-size: 1rem;
		    font-weight: normal;
		    color: color(functional,text);
	    }
	}
	

	.views-field-field-author-image{
		width: 70px;
		height: 70px;
		float: left;
		margin-right: 12px;

		img{
			display: block;
		}
	}
	.views-field-field-twitter{
		padding-top: 1.5rem;
	}

	.view-footer{
		position: absolute;
		left: 82px;
		bottom: 24px;
	}
}

#disqus_thread{
  margin:50px 0 20px;
}

.video_background { 
    position: absolute;
    top: 60px; //nav height
    left: 0;
    width: 100%;
    height:60vh; // big enough for 5 lines titles
	overflow: hidden;    
    z-index: -100;
	transition: 1s opacity;

	  video {
	  	position: absolute;
	    top: 0;
        left: 50%;
        transform: translateX(-50%);
	    height: 100%;
	    background-size: cover;
	  }

	@include respond-to(s) {
		height: 0;
		padding-bottom: 56.25%;

		video{
			width: 100%;
		}
	}  
}

.views-exposed-form .views-exposed-widget .form-submit{
	margin-top: 0;
}

.btn-header-search{ // search button
	float: right;
	margin: 10px 12px 0 40px;
	position: relative; // important for this custom header

	a {
		transition: color .3s ease-out;
		color: #fff;
		font-size: 22px;
		line-height: 48px;
		display: block;
		padding-left: 1px; //font correction

		&:hover {
			color: color('orange');
		}
	}
}

#block-block-1,
#block-block-3 {
	ul{
		margin: 0;
	}

	li {
		display: inline-block;
		margin: 0 4px 0 0;
		vertical-align: middle;

		a {
			transition: color .3s ease-out;
			color: #fff;
			font-size: 22px;

			&:hover {
				color: color('orange');
			}
		}
	}
	@include zen-respond-to(l) {
		text-align: right;
	}
}

#block-block-3{
	text-align: right;
	ul{
		padding: 5px 10px 0 0;
		a{
			line-height: 48px;
		}
		@include zen-respond-to(l) {
			padding: 0;
			width: 30px;
			height: 50px;
		}
	}

	@include respond-to(l) {
		float: right;
		margin-left: 0;

		ul li a{
			color: #999;
		}

		.icon-instagram,
		.icon-linkedin,
		.icon-twitter,
		.icon-youtube{
			display: none;
		}
	}	
}

#block-views-exp-search-page{
	margin: 0;
	position: absolute;
	bottom: -81px;
	left: 0;
	right: 0;

	form{
		max-width: 1213px;
		margin: 0 auto;
		text-align: right;

		>div{
			background: #fff;
			padding: 20px;
			display: inline-block;
		}
	}

	.form-text{
		width: 170px;
		padding: 5px 3px 3px;
	}
	.views-exposed-widget{
		padding: 0;
	}
	.views-exposed-widgets{
		margin-bottom: 0;
	}
	.views-submit-button{
		input{
			border:0;
			background:color('orange') url(../images/ico-search.svg) center center no-repeat;
			background-size: 26px 26px;
			text-indent: 40px;
			width: 38px;
			height: 34px;
			overflow: hidden;
		}
	}
	@include respond-to(xl) {
		bottom: auto;
		top: 60px;
	}
}

.addtoany_list{
	padding: 20px 0 0;
	display: block;

	a span{
		width: 37px!important;
		height: 37px!important;
		border-radius: 50%!important;
	}

	.a2a_svg svg{
		top: 6%!important;
		width: 86%!important;
	}
}

@media only screen and (max-width: 888px) {
	article.node-page.view-mode-full .field-name-body figure.image{
		float: none!important;
		margin: 0;
	}
}

#sliding-popup .popup-content {
	max-width: 100%;
	padding: 0 12px;
}

#sliding-popup .popup-content #popup-text {
	font-size: 13px;
	font-weight: normal;
}

#sliding-popup .popup-content #popup-text p {
	font-size: 13px;
	font-weight: normal;
}

#sliding-popup .eu-cookie-compliance-secondary-button {
	font-size: 14px;
}

#sliding-popup .eu-cookie-compliance-default-button {
	font-size: 14px;
	border-radius: 0;
}

/*
.eu-cookie-compliance-processed{
	padding-bottom: 70px;
}*/