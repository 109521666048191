// sass-lint:disable no-vendor-prefixes

// Use the saner border-box model for all elements.
* {
  @if support-for(firefox, 28) {
    -moz-box-sizing: border-box;
  }
  box-sizing: border-box;
}

html {
  @include define-type-sizing();
  // Set default font family to sans-serif.
  @include typeface(body);
  // Prevent iOS text size adjust after orientation change, without disabling
  // user zoom.
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  // On short pages, we want any background gradients to fill the entire height
  // of the browser.
  min-height: 100%;
  background: url('../images/bg.png') 0 0 repeat;
}

body {
  // Output a horizontal grid to help with debugging typography. The
  // $typey-debug variable will toggle its output.
  @include typey-debug-grid();
  // Remove default margin and padding.
  margin: 0;
  padding: 0;
  color: color(text);
  background-size: auto 60vh!important;
  @include zen-respond-to(xs) {
    background-size: 100% auto !important;
  }
}
